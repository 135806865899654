export const HOME = {
  TITLE: 'Ferienwohnung Martinshof',
  DESCRIPTION: 'Ferienwohnung Martinshof in Sexau bei Freiburg',
  KEYWORDS: [],

  HEADLINE: 'Willkommen am Martinshof',
  RESERVE_BUTTON: 'Reservieren',
  MORE_PHOTOS_BUTTON: 'Weitere Fotos',
};

export const PHOTOS = {
  TITLE: 'Ferienwohnung Martinshof - Photos',
  DESCRIPTION: 'Ferienwohnung Martinshof in Sexau bei Freiburg',
  KEYWORDS: [],
};

export const BOOKING = {
  TITLE: 'Ferienwohnung Martinshof - Reservierung',
  DESCRIPTION: 'Ferienwohnung Martinshof in Sexau bei Freiburg',
  KEYWORDS: [],
};

export const EMAIL = 'ferienwohnungmartinshof@gmail.com';
