import React, { useState } from 'react';
import Layout from '../components/layout';

function LayoutContainer(props) {
  const [showNav, setShowNav] = useState(false);

  function handleShowNav() {
    setShowNav(true);
  }

  function handleHideNav() {
    setShowNav(false);
  }

  return (
    <Layout
      {...props}
      showNav={showNav}
      siteTitle={'title'}
      onHideNav={handleHideNav}
      onShowNav={handleShowNav}
      subtitle={'sub title'}
      currentPage={props.currentPage}
    />
  );
}

export default LayoutContainer;
