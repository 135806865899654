import Layout from '../containers/layout';
import styled from 'styled-components';
import 'react-image-gallery/styles/css/image-gallery.css';
import SEO from '../components/seo';
import {ContainerBodyWidth} from '../components/ContainerBodyWidth';
import {BOOKING, EMAIL} from '../constants/content';
import {InfoHeadline} from '../components/InfoHeadline';
import {InfoLine} from '../components/InfoLine';

export const Title = styled.h2`
  /* text-align: center; */
    /* color: ${props => props.theme.colors.blue}; */
`;

const Reservieren = () => {
  const site = {
    title: BOOKING.TITLE,
    description: 'description',
    keywords: [],
  };

  return (
    <Layout currentPage="reserve">
      <SEO title={BOOKING.TITLE} description={site.description} keywords={site.keywords}/>

      <ContainerBodyWidth>
        <div style={{padding: 20}}>
          <Title>Reservieren</Title>
          <p style={{fontSize: 16}}>
            Kontaktieren Sie uns bitte per Telefon oder E-Mail. Wir bearbeiten Ihre Anfrage
            schnellstmöglich und freuen uns auf Sie!
          </p>
          <br/>
          <InfoHeadline>Preise</InfoHeadline>
          <InfoLine>2 Erwachsene 90 € / Nacht</InfoLine>
          <InfoLine>Jede weitere Pers. 5 € / Nacht</InfoLine>
          <InfoLine>Zzgl. 70 € Endreinigung</InfoLine>
          <InfoLine>Kurtaxe enthalten</InfoLine>

          <br/>
          <InfoHeadline>Kontakt</InfoHeadline>
          <InfoLine>Ferienwohnung Martinshof</InfoLine>
          <InfoLine>Familie Knecht</InfoLine>

          <br/>
          <InfoHeadline>Adresse</InfoHeadline>
          <InfoLine>Am Reichenbächle 53</InfoLine>
          <InfoLine>79350 Sexau</InfoLine>

          <br/>
          <InfoHeadline>Telefon</InfoHeadline>
          <InfoLine>
            <a href="tel:+4976419338282">+49-(0)7641 93 38 282</a>
          </InfoLine>

          <br/>
          <InfoHeadline>Email</InfoHeadline>
          <InfoLine>
            <a href={`mailto:${EMAIL}`} target="_blank" rel="noreferrer">
              {EMAIL}
            </a>
          </InfoLine>
        </div>
      </ContainerBodyWidth>
    </Layout>
  );
};

export default Reservieren;
